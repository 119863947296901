<template>
  <v-container fluid>
    <v-row>
      <v-text-field solo v-model="getSource.fileName" :label="$t('label.fileName')" disabled></v-text-field>
    </v-row>
    <v-row class="d-flex flex-column">
      <v-card class="py-3">
        <v-row class="mx-0">
          <input type="file" accept=".csv" ref="fileInput" class="d-none" @change="onFileSelected" />
          <v-col cols="12" class="d-flex flex-column align-center" v-if="$vuetify.breakpoint.xs">
            <v-btn class="ma-3" @click="selectFile" text>{{ $t('externalData.selectFile') }}</v-btn>
          </v-col>
          <v-col cols="12" v-else>
            <div class="drag-drop-area pa-3 ma-3" @dragover.prevent @drop.prevent="onDrop">
              <v-btn @click="selectFile" text>{{ $t('externalData.selectOrDropFile') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "upload-file-component",
  props: {
    update: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapFields("externalSource", ["externalSource", "selectedDocument"]),
    getSource() {
      if (this.update) {
        return this.selectedDocument
      } else {
        return this.externalSource
      }
    }
  },
  methods: {
    ...mapActions("alert", ["createAlert"]),
    selectFile() {
      this.$refs.fileInput.click();
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      this.uploadFile(file)
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.uploadFile(file)
    },
    uploadFile(file) {
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
        this.getSource.fileName = file.name;
        this.getSource.file = file;
        this.createAlert({ message: this.$t('message.fileUplaodedSuccess'), type: "success" })
      } else {
        this.createAlert({ message: this.$t('message.wrongFileTypeError'), type: "error" })
      }
    }
  },
};
</script>
<style scoped>
.drag-drop-area {
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
</style>